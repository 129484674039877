<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t("meta.metaTags") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div
                class="col-auto p-0 m-0 mb-3"
                v-for="l in languages"
                :key="l.code"
            >
              <button
                  class="btn btn-tab"
                  :class="{ active: l.code === selectedLanguage }"
                  v-on:click="selectedLanguage = l.code"
              >
                <span class="semibold-14">{{ l.name }}</span>
              </button>
            </div>
          </div>
          <div class="row" v-if="modelValueData !== undefined">
            <div class="col-12 col-lg-7 text-start custom-input whitebg-input">
              <div>
                <label class="form-label bold-12 text-gray">{{ $t("meta.url") }}</label>
                <div class="fix-start-input">
                  <span>/product/{{id}}-</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="modelValueData.slug[selectedLanguage]"
                    v-on:change="change"
                    v-on:keyup="change"
                  />
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label bold-12 text-gray">{{ $t("meta.title") }}</label>
                <input
                  type="text"
                  v-model="modelValueData.titles[selectedLanguage]"
                  class="form-control"
                  v-on:change="change"
                  v-on:keyup="change"
                />
              </div>
              <div class="mt-3">
                <label class="form-label bold-12 text-gray">{{ $t("meta.description") }}</label>
                <input
                  type="text"
                  v-model="modelValueData.short_descriptions[selectedLanguage]"
                  class="form-control"
                  v-on:change="change"
                  v-on:keyup="change"
                />
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div class="google-preview">
                <div class="google-preview-url">https://domain.hu/product/{{id}}-{{ modelValueData.slug[selectedLanguage] !== undefined ? modelValueData.slug[selectedLanguage] : modelValueData.slug['en'] }}</div>
                <div class="google-preview-title">{{ modelValueData?.titles[selectedLanguage]?.length >0 ? modelValueData.titles[selectedLanguage] : modelValueData?.name[selectedLanguage] }}</div>
                <div class="google-preview-desc">{{ modelValueData.short_descriptions[selectedLanguage] }}</div>
              </div>
              <div class="fb-preview mt-5">
                <div class="fb-preview-url">domain.hu</div>
                <div class="fb-preview-title">{{ modelValueData?.titles[selectedLanguage]?.length >0 ? modelValueData.titles[selectedLanguage] : modelValueData?.name[selectedLanguage] }}</div>
                <div class="fb-preview-desc" :class="{hide: modelValueData?.titles[selectedLanguage]?.length > 44}">{{ modelValueData?.short_descriptions[selectedLanguage] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import http from "../../modules/http";

const bootstrap = require("bootstrap");

export default {
  name: "Meta",
  props: {
    modelValue: Object,
    show: Boolean,
    ajaxUrl: String,
    id: Number,
  },
  emits: ["update:modelValue", "update:show", "change"],
  data: function () {
    return {
      modelValueData: undefined,
      selectedLanguage: "en",
      needSave: false,
      saveTime: null,
    };
  },
  mounted() {
    let myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      myModal.show();
    } else {
      myModal.hide();
    }

    if (this.modelValueData === undefined && this.ajaxUrl !== undefined) {
      http.fetch(this.ajaxUrl + "?id=" + this.id).then((data) => {
        this.modelValueData = data;
        this.needSave = false;
      });
    }
  },
  computed: {
    languages() {
      const store = useStore();
      return store.state.languages;
    },
  },
  watch: {
    show() {
      let myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      if (this.show) {
        myModal.show();
      } else {
        myModal.hide();
      }
    },
  },
  methods: {
    change() {
      this.needSave = true;
      if (this.saveTime === null) {
        this.saveTime = setTimeout(this.save, 2000);
      }
    },
    closeModal() {
      this.$emit("update:show", false);
      this.save();
    },
    save() {
      if (this.needSave) {
        this.$emit("update:modelValue", this.modelValueData);
        this.$emit("change", this.modelValueData);
        this.needSave = false;
        if (this.saveTime !== null) {
          clearInterval(this.saveTime);
          this.saveTime = null;
        }
      }
    },
  },
  components: {},
};
</script>
